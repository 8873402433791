export const iowaCounties = [
  "Adair",
  "Adams",
  "Allamakee",
  "Appanoose",
  "Audubon",
  "Benton",
  "Black Hawk",
  "Boone",
  "Bremer",
  "Buchanan",
  "Buena Vista",
  "Butler",
  "Calhoun",
  "Carroll",
  "Cass",
  "Cedar",
  "Cerro Gordo",
  "Cherokee",
  "Chickasaw",
  "Clarke",
  "Clay",
  "Clayton",
  "Clinton",
  "Crawford",
  "Dallas",
  "Davis",
  "Decatur",
  "Delaware",
  "Des Moines",
  "Dickinson",
  "Dubuque",
  "Emmet",
  "Fayette",
  "Floyd",
  "Franklin",
  "Fremont",
  "Greene",
  "Grundy",
  "Guthrie",
  "Hamilton",
  "Hancock",
  "Hardin",
  "Harrison",
  "Henry",
  "Howard",
  "Humboldt",
  "Ida",
  "Iowa",
  "Jackson",
  "Jasper",
  "Jefferson",
  "Johnson",
  "Jones",
  "Keokuk",
  "Kossuth",
  "Lee",
  "Linn",
  "Louisa",
  "Lucas",
  "Lyon",
  "Madison",
  "Mahaska",
  "Marion",
  "Marshall",
  "Mills",
  "Mitchell",
  "Monona",
  "Monroe",
  "Montgomery",
  "Muscatine",
  "O'Brien",
  "Osceola",
  "Page",
  "Palo Alto",
  "Plymouth",
  "Pocahontas",
  "Polk",
  "Pottawattamie",
  "Poweshiek",
  "Ringgold",
  "Sac",
  "Scott",
  "Shelby",
  "Sioux",
  "Story",
  "Tama",
  "Taylor",
  "Union",
  "Van Buren",
  "Wapello",
  "Warren",
  "Washington",
  "Wayne",
  "Webster",
  "Winnebago",
  "Winneshiek",
  "Woodbury",
  "Worth",
  "Wright",
];

export const overviewColumnNames = [
  "Hospital Name",
  "Trauma Levels",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
  "Total ICU Beds",
  "Total ICU Beds Occupied",
  "Total ICU Beds Available",
  "Percentage Occupied ICU",
  "Total ER Beds",
  "Total ER Beds Occupied",
  "Total ER Beds Available",
  "Percentage Occupied ER",
];

export const adultTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total ICU Licensed Beds",
  "Total ICU Occupied Beds",
  "Total ICU Beds Available",
  "ICU Percentage Occupied",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];

export const pediatricTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total ICU Licensed Beds",
  "Total ICU Occupied Beds",
  "Total ICU Beds Available",
  "ICU Percentage Occupied",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
  "Total Neo Licensed Beds",
  "Total Neo Occupied Beds",
  "Total Neo Beds Available",
  "Neo Percentage Occupied",
];
export const mentalHealthTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];

export const psychiatryAllBedsTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];

export const psychiatryBedsTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total Child Licensed Beds",
  "Total Child Occupied Beds",
  "Total Child Beds Available",
  "Child Percentage Occupied",
  "Total Adult Licensed Beds",
  "Total Adult Occupied Beds",
  "Total Adult Beds Available",
  "Adult Percentage Occupied",
  "Total Older Licensed Beds",
  "Total Older Occupied Beds",
  "Total Older Beds Available",
  "Older Percentage Occupied",
];
export const momBabyTabColumnNames = [
  "Hospital Name",
  "Hospital Type",
  "NMDS",
  "Hospital City",
  "Hospital County",
  "Hospital Zip",
  "AHA Number",
  "RMCC",
  "HCC",
  "Total L&D Licensed Beds",
  "Total L&D Occupied Beds",
  "Total L&D Beds Available",
  "L&D Percentage Occupied",
  "Total Licensed Beds",
  "Total Occupied Beds",
  "Total Beds Available",
  "Percentage Occupied",
];
export const rmccOptions = ["1", "2", "3", "4", "5"];

export const hccOptions = ["1A", "1C", "2", "3", "4", "5", "6", "7"];

export const listOfFacilities = [
  {
    facility_code: "IA-ADAIRCNTY-HOSP",
    facility_name: "ADAIR COUNTY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-ACMH-HOSP",
    facility_name: "AUDUBON COUNTY MEMORIAL HOSPITAL & CLINICS",
  },
  {
    facility_code: "IA-AVEHFH-HOSP",
    facility_name: "AVERA HOLY FAMILY HOSPITAL",
  },
  {
    facility_code: "IA-AVEMPH-HOSP",
    facility_name: "AVERA MERRILL PIONEER HOSPITAL",
  },
  {
    facility_code: "IA-BOONECNTY-HOSP",
    facility_name: "BOONE COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-BROADLAWNS-HOSP",
    facility_name: "BROADLAWNS MEDICAL CENTER",
  },
  {
    facility_code: "IA-CCH-BCI-HOSP-BCI",
    facility_name: "BUCHANAN COUNTY HEALTH CENTER",
  },
  {
    facility_code: "IA-CCH-BVM-HOSP-BVM",
    facility_name: "BUENA VISTA REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-BURGESS-HOSP",
    facility_name: "BURGESS HEALTH CENTER",
  },
  {
    facility_code: "IA-CCHS-HOSP",
    facility_name: "CASS HEALTH",
  },
  {
    facility_code: "IA-CCH-CRH-HOSP-CRH",
    facility_name: "CHEROKEE REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-CHI-MC-HOSP",
    facility_name: "CHI HEALTH MERCY CORNING",
  },
  {
    facility_code: "IA-CHI-MCB-HOSP",
    facility_name: "CHI HEALTH MERCY COUNCIL BLUFFS",
  },
  {
    facility_code: "IA-CHI-MV-HOSP",
    facility_name: "CHI HEALTH MISSOURI VALLEY",
  },
  {
    facility_code: "IA-CLARINDA-HOSP",
    facility_name: "CLARINDA REGIONAL HEALTH CENTER",
  },
  {
    facility_code: "IA-CCH-CCO-HOSP-CCO",
    facility_name: "CLARKE COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-CCH-CMS-HOSP-CMS",
    facility_name: "COMMUNITY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-CCH-MMH-HOSP-MMH",
    facility_name: "COMPASS MEMORIAL HEALTHCARE",
  },
  {
    facility_code: "IA-CRACMH-HOSP",
    facility_name: "CRAWFORD COUNTY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-CCMH-HOSP",
    facility_name: "CRAWFORD COUNTY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-DALLASCNTY-HOSP",
    facility_name: "DALLAS COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-DAVISCNTY-HOSP",
    facility_name: "DAVIS COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-DECATR-HOSP",
    facility_name: "DECATUR COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-FLOYDCNTY-HOSP",
    facility_name: "FLOYD COUNTY MEDICAL CENTER",
  },
  {
    facility_code: "IA-FLOVHC-HOSP",
    facility_name: "FLOYD VALLEY HEALTHCARE",
  },
  {
    facility_code: "IA-FRANKLIN-HOSP",
    facility_name: "FRANKLIN GENERAL HOSPITAL",
  },
  {
    facility_code: "IA-CCH-GRH-HOSP-GRH",
    facility_name: "GREATER REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-CCH-GCJ-HOSP-GCJ",
    facility_name: "GREENE COUNTY MEDICAL CENTER",
  },
  {
    facility_code: "IA-CCH-GRU-HOSP-GRU",
    facility_name: "GRUNDY COUNTY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-CCH-GCH-HOSP-GCH",
    facility_name: "GUTHRIE COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-CCH-GMH-HOSP-GMH",
    facility_name: "GUTTENBERG MUNICIPAL HOSPITAL",
  },
  {
    facility_code: "IA-HANCOCKCNTY-HOSP",
    facility_name: "HANCOCK COUNTY HEALTH SYSTEM",
  },
  {
    facility_code: "IA-HANSEN-HOSP",
    facility_name: "HANSEN FAMILY HOSPITAL",
  },
  {
    facility_code: "IA-HAWARDEN-HOSP",
    facility_name: "HAWARDEN HEALTH CENTER",
  },
  {
    facility_code: "IA-HEGGHC-HOSP",
    facility_name: "HEGG HEALTH CENTER",
  },
  {
    facility_code: "IA-GRHS-HENRYCNTY-HOSP",
    facility_name: "HENRY COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-HORNMH-HOSP",
    facility_name: "HORN MEMORIAL",
  },
  {
    facility_code: "IA-CCH-HCH-HOSP-HCH",
    facility_name: "HUMBOLDT COUNTY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-ISHCB-HOSP",
    facility_name: "IOWA SPECIALTY HOSPITAL-BELMOND",
  },
  {
    facility_code: "IA-ISHCC-HOSP",
    facility_name: "IOWA SPECIALTY HOSPITAL-CLARION",
  },
  {
    facility_code: "IA-JCHC-HOSP",
    facility_name: "JEFFERSON COUNTY HEALTH CENTER",
  },
  {
    facility_code: "IA-KEOKUKHC-HOSP",
    facility_name: "KEOKUK COUNTY HEALTH CENTER",
  },
  {
    facility_code: "IA-KNOSVL-HOSP",
    facility_name: "KNOXVILLE HOSPITAL & CLINICS",
  },
  {
    facility_code: "IA-KOSSUTH-HOSP",
    facility_name: "KOSSUTH REGIONAL HEALTH CENTER - HOSPITAL",
  },
  {
    facility_code: "IA-LAERHC-HOSP",
    facility_name: "LAKES REGIONAL HEALTHCARE",
  },
  {
    facility_code: "IA-CCH-LSC-HOSP-LSC",
    facility_name: "LORING HOSPITAL",
  },
  {
    facility_code: "IA-CCH-LCH-HOSP-LCH",
    facility_name: "LUCAS COUNTY HEALTH CENTER",
  },
  {
    facility_code: "IA-MADISCNTY-HOSP",
    facility_name: "MADISON COUNTY HEALTHCARE SYSTEM",
  },
  {
    facility_code: "IA-CCH-MOH-HOSP-MOH",
    facility_name: "MAHASKA COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-MANNG-HOSP",
    facility_name: "MANNING REGIONAL HEALTHCARE CENTER",
  },
  {
    facility_code: "IA-MARGMC-HOSP",
    facility_name: "MARY GREELEY MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYCARECR-HOSP",
    facility_name: "MERCY MEDICAL CENTER - CEDAR RAPIDS",
  },
  {
    facility_code: "IA-MERCYCDRFALLS-HOSP",
    facility_name: "MERCYONE CEDAR FALLS MEDICAL CENTER",
  },
  {
    facility_code: "IA-CSDSM-MCV-CMC-HOSP",
    facility_name: "MERCYONE CENTERVILLE MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYCLINTON-HOSP",
    facility_name: "MERCYONE CLINTON MEDICAL CENTER",
  },
  {
    facility_code: "IA-CSDSM-MMC-DSM-HOSP",
    facility_name: "MERCYONE DES MOINES MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYDUBUQUE-HOSP",
    facility_name: "MERCYONE DUBUQUE MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYDYERSVILLE-HOSP",
    facility_name: "MERCYONE DYERSVILLE MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYELK-HOSP",
    facility_name: "MERCYONE ELKADER MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYNH-HOSP",
    facility_name: "MERCYONE NEW HAMPTON MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCNEW-HOSP",
    facility_name: "MERCYONE NEWTON MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYMASONCTY-HOSP",
    facility_name: "MERCYONE NORTH IOWA MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYOELW-HOSP",
    facility_name: "MERCYONE OELWEIN MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYPRIMGHR-HOSP",
    facility_name: "MERCYONE PRIMGHAR MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYSIOUXL-HOSP",
    facility_name: "MERCYONE SIOUXLAND MEDICAL CENTER",
  },
  {
    facility_code: "IA-MERCYWATR-HOSP",
    facility_name: "MERCYONE WATERLOO MEDICAL CENTER",
  },
  {
    facility_code: "IA-CSDSM-MMC-MWL-HOSP",
    facility_name: "MERCYONE WEST DES MOINES MEDICAL CENTER",
  },
  {
    facility_code: "IA-MITCHELLCNTY-HOSP",
    facility_name: "MITCHELL COUNTY REGIONAL HEALTH CENTER",
  },
  {
    facility_code: "IA-MONROECNTY-HOSP",
    facility_name: "MONROE COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-MONMC-HOSP",
    facility_name: "MONTGOMERY COUNTY MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-MYRTUE-HOSP",
    facility_name: "MYRTUE MEDICAL CENTER",
  },
  {
    facility_code: "IA-OSCEOLARMC-HOSP",
    facility_name: "OSCEOLA REGIONAL HEALTH CENTER",
  },
  {
    facility_code: "IA-OTTRHC-HOSP",
    facility_name: "OTTUMWA REGIONAL HEALTH CENTER",
  },
  {
    facility_code: "IA-CCH-PCH-HOSP-PCH",
    facility_name: "POCAHONTAS COMMUNITY HOSPITAL",
  },
  {
    facility_code: "IA-HOWARDCNTY-HOSP",
    facility_name: "REGIONAL HEALTH SERVICES OF HOWARD COUNTY",
  },
  {
    facility_code: "IA-REGMC-HOSP",
    facility_name: "REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-RINGGOLDCNTY-HOSP",
    facility_name: "RINGGOLD COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-SIOUXCH-HOSP",
    facility_name: "SIOUX CENTER HEALTH",
  },
  {
    facility_code: "IA-GRHS-BURLINGT-HOSP",
    facility_name: "SOUTHEAST IOWA REGIONAL MEDICAL CENTER - BURLINGTON",
  },
  {
    facility_code: "IA-GRHS-FTMADISON-HOSP",
    facility_name: "SOUTHEAST IOWA REGIONAL MEDICAL CENTER - FORT MADISON",
  },
  {
    facility_code: "IA-SPENCER-HOSP",
    facility_name: "SPENCER HOSPITAL",
  },
  {
    facility_code: "IA-STANTHONY-HOSP",
    facility_name: "ST ANTHONY REGIONAL HOSPITAL",
  },
  {
    facility_code: "IA-CCH-SMH-HOSP-SMH",
    facility_name: "STEWART MEMORIAL COMMUNITY HOSPITAL",
  },
  {
    facility_code: "IA-CCH-SCN-HOSP-SCN",
    facility_name: "STORY COUNTY MEDICAL CENTER",
  },
  {
    facility_code: "IA-UPH-WLA-HOSP-WLA",
    facility_name: "UNITYPOINT HEALTH-ALLEN MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-UPH-DBF-HOSP-DBF",
    facility_name: "UNITYPOINT HEALTH-FINLEY HOSPITAL",
  },
  {
    facility_code: "IA-UPH-DMG-HOSP-DMG",
    facility_name: "UNITYPOINT HEALTH-GRINNELL REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-UPH-DML-HOSP-DML",
    facility_name: "UNITYPOINT HEALTH-IOWA LUTHERAN HOSPITAL",
  },
  {
    facility_code: "IA-UPH-DMM-HOSP-DMM",
    facility_name: "UNITYPOINT HEALTH-IOWA METHODIST MEDICAL CENTER",
  },
  {
    facility_code: "IA-UPH-JRM-HOSP-JRM",
    facility_name: "UNITYPOINT HEALTH-JONES REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-UPH-WMT-HOSP-WMT",
    facility_name: "UNITYPOINT HEALTH-MARSHALLTOWN",
  },
  {
    facility_code: "IA-UPH-DMW-HOSP-DMW",
    facility_name: "UNITYPOINT HEALTH-METHODIST WEST HOSPITAL",
  },
  {
    facility_code: "IA-UPH-CRS-HOSP-CRS",
    facility_name: "UNITYPOINT HEALTH-ST. LUKE'S HOSPITAL",
  },
  {
    facility_code: "IA-UPH-SCS-HOSP-SCS",
    facility_name:
      "UNITYPOINT HEALTH-ST. LUKE'S REGIONAL MEDICAL CENTER  SIOUX CITY",
  },
  {
    facility_code: "IA-UPH-QCB-HOSP-QCB",
    facility_name: "UNITYPOINT HEALTH-TRINITY BETTENDORF",
  },
  {
    facility_code: "IA-UPH-QMU-HOSP-QMU",
    facility_name: "UNITYPOINT HEALTH-TRINITY MUSCATINE",
  },
  {
    facility_code: "IA-UPH-FDT-HOSP-FDT",
    facility_name: "UNITYPOINT HEALTH-TRINITY REGIONAL MEDICAL CENTER",
  },
  {
    facility_code: "IA-UIHC-HOSP",
    facility_name: "UNIVERSITY OF IOWA",
  },
  {
    facility_code: "IA-VANBUR-HOSP",
    facility_name: "VAN BUREN COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-VANDIEST-HOSP",
    facility_name: "VAN DIEST MEDICAL CENTER",
  },
  {
    facility_code: "IA-VETMEM-HOSP",
    facility_name: "VETERANS MEMORIAL HOSPITAL",
  },
  {
    facility_code: "IA-CCH-VGH-HOSP-VGH",
    facility_name: "VIRGINIA GAY HOSPITAL INC",
  },
  {
    facility_code: "IA-WAVEHC-HOSP",
    facility_name: "WAVERLY HEALTH CENTER",
  },
  {
    facility_code: "IA-WAYNECNTY-HOSP",
    facility_name: "WAYNE COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-WMC-HOSP",
    facility_name: "WINN MED",
  },
  {
    facility_code: "IA-GCGRAPE-HOSP",
    facility_name: "GEORGE C GRAPE COMMUNITY HOSPITAL",
  },
  {
    facility_code: "IA-WCHC-HOSP",
    facility_name: "WASHINGTON COUNTY HOSPITAL",
  },
  {
    facility_code: "IA-HCMCD-HOSP",
    facility_name: "UNIVERSITY OF IOWA - DOWNTOWN",
  },
];
